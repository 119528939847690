.mainContainer {
  box-shadow: theme(boxShadow.4);
  border-radius: theme(borderRadius.4);

  box-sizing: border-box;
  padding: 16px 24px 16px 24px;

  display: flex;
  align-items: center;
  justify-items: center;

  gap: theme(spacing.3);

  width: 600px;
  height: fit-content;
  background-color: white;
  &[data-variant='success'] {
    background-color: theme(colors.success50);
  }
  &[data-variant='error'] {
    background-color: theme(colors.error100);
  }
}

.iconContainer {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-items: center;
  border-radius: theme(borderRadius.8);
  overflow: hidden;

  .icon {
    width: 33.61px;
    height: 33.61px;
    fill: theme(colors.tertiary500);
    color: white;

    [data-variant='success'] & {
      fill: theme(colors.success500);
      color: theme(colors.success50);
    }
    [data-variant='error'] & {
      fill: theme(colors.error500);
      color: theme(colors.error50);
    }
  }

  .image {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.textContainer {
  flex: auto;

  & .title {
    @apply text-body-sm-bold;
    color: theme(colors.tertiary500);
  }

  .message {
    @apply text-caption-lg-regular break-words;
  }
}

.buttonWithText {
  padding: 9.5px theme(spacing.3);
  font-weight: 600;
}

.crossButton {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: theme(borderRadius.8);
  padding: theme(spacing.1);
  height: 32px;
  width: 32px;

  transition: background-color 0.08s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  & > span {
    display: flex;

    justify-content: center;
    align-items: center;
  }
  & .crossIcon {
    width: 24px;
    height: 24px;
    color: theme(colors.tertiary500);
  }
}

.ToastViewport {
  --viewport-padding: 25px;
  position: fixed;
  transform: translateX(-50%);
  top: 8%;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: fit-content;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
}

.ToastRoot {
  &[data-state='open'] {
    animation: slideIn 250ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-state='closed'] {
    animation: hide 150ms ease-in;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateY(0);
  }
}
