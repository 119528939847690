.spinner {
  animation: rotation 0.5s infinite linear;
  border-radius: 100%;
  box-sizing: border-box;
  border-style: solid;

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    border-style: solid;
    border-radius: 100%;
  }
}

[data-weight='thin'] {
  &.spinner {
    border-width: 2px !important;

    &::before {
      top: -2px !important;
      border-width: 2px !important;
    }
  }
}

[data-size='small'] {
  &.spinner {
    height: 21px;
    width: 21px;
    border-width: 3px;

    &::before {
      top: -3px;
      border-width: 3px;
    }
  }
}

[data-size='medium'] {
  &.spinner {
    height: 42px;
    width: 42px;
    border-width: 6px;

    &::before {
      top: -6px;
      border-width: 6px;
    }
  }
}

[data-size='large'] {
  &.spinner {
    height: 64px;
    width: 64px;
    border-width: 10px;

    &::before {
      top: -10px;
      border-width: 10px;
    }
  }
}

[data-color='primary'] {
  &.spinner {
    border-color: theme('colors.primary50');

    &::before {
      border-color: theme('colors.primary500') transparent transparent transparent;
    }
  }
}

[data-color='secondary'] {
  &.spinner {
    border-color: theme('colors.secondary100');

    &::before {
      border-color: theme('colors.secondary500') transparent transparent transparent;
    }
  }
}

[data-color='tertiary'] {
  &.spinner {
    border-color: theme('colors.tertiary25');

    &::before {
      border-color: theme('colors.tertiary500') transparent transparent transparent;
    }
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
